function setEqualHeight(){
    $('.product-single-countdown .content').matchHeight();
    // $('.grid-product .grid-element-content-header-image').matchHeight();
    $('.grid-product .grid-element-title').matchHeight();
    $('.grid-product .product-options').matchHeight();
    $('.grid-product .stock-container').matchHeight();
    $('.grid-product').matchHeight();
    $(".prva-prednosti-enako").matchHeight();
    $(".prednosti-podrobno").matchHeight();
}
$(window).on("load",function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height",maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height",maxHeight + "px");
                }
        	}
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height","");
            $('#main-menu').css("height","");
        });
    }
});

$(window).bind('scroll', function() {
    var scroll = 145,
        skelBreakpoints = skel.vars.stateId;
    if (skelBreakpoints.search("/medium") > -1) {
        scroll = 114;
    }
    if (skelBreakpoints.search("/xsmall") > -1) {
        scroll = 181;
    }
    if ($(window).scrollTop() > scroll) {
        $('body').addClass('fixed-nav');
    } else {
        $('body').removeClass('fixed-nav');
    }
});
